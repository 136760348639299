import React from 'react';

import { Layout } from '../components';

import './legal-returns-app.scss';

const Terms = () => (
  <Layout>
    <div className="wrapper">
      <div className="legal-returns-app">
        <h1 className="legal-returns-app__title">
          <strong>TERMS OF SERVICE</strong>
        </h1>
        <p>&nbsp;</p>
        <h3>MASTER TERMS OF SERVICE</h3>
        <p>
          Maxwell Forest, Inc. and its affiliates (“Maxwell Forest”, “we”, “us”, “our”) has redefined personal security
          with the Safedome card and app (collectively “Safedome”), which connect a user’s wallet and smartphone to help
          them manage the risk of theft, loss and fraud. The Safedome card, app, website (the “Site”), and the
          Integrated Services (as defined in Section 4) are collectively referred to herein as the “Service.” This
          Master Terms of Service and the Additional Terms referenced in Section 2 (collectively, the “Terms”) govern
          your use of the Service.
        </p>

        <p>
          By ordering a Safedome card, visiting the Site, or otherwise accessing or using any portion of the Service,
          you agree that you have read, understood and agree to be bound by these Terms. If you do not agree you are not
          permitted to use the Service.
        </p>

        <p>
          You are solely responsible for your use of the Service. By using the Service you acknowledge that you do so at
          your own risk.
        </p>

        <p>
          These Terms may be changed in whole or in part. If you are in the US the amended Terms will be effective from
          the date as notified to you or when the amended Terms of Service are made available on the Site. If you are in
          Australia we will provide you with sufficient notice of the proposed amended Terms.{' '}
        </p>

        <h6>1. ADDITIONAL TERMS</h6>
        <p>
          In addition to these Master Terms of Service the following Additional Terms apply. By using the Service, you
          agree to be bound by these Additional Terms (including any age, geographic or legal restrictions applicable to
          the use of a particular feature of the Service):
        </p>

        <ul>
          <li>
            Privacy Policy, which describes Maxwell Forest’s policies governing the collection, use and disclosure of
            personally identifiable information;
          </li>
          <li>
            End User License Agreement (EULA), which governs your use of the software embedded on the Safedome card;
          </li>
          <li>Safedome Card Limited Warranty; and</li>
          <li>
            any additional terms or conditions that may be presented or notified to you by our affiliated third party
            service providers.
          </li>
        </ul>

        <p>
          In the event of a conflict between the Master Terms of Service in this document and the Additional Terms,
          these Master Terms of Service take precedence.
        </p>

        <h6>2. ORDERS</h6>
        <h6 className="legal-returns-app__para-subtitle">2.1 Pre-Orders on or after July 10, 2015</h6>
        <p>By pre-ordering Safedome on or after July 10, 2015 you:</p>
        <ol>
          <li>
            acknowledge and agree that you are contributing (i.e. making a donation) to a work in progress and not
            making a direct purchase;
          </li>
          <li>
            agree that in exchange for your contribution you will receive the proportionate number of Safedome cards
            (i.e. a card for each pre-order). If we are unable to fulfill your contribution, the parties agree that
            these Terms will apply (including Dispute Resolution terms);
          </li>
          <li>confirm that you are over the age of 18;</li>
          <li>
            acknowledge that Safedome is not an insurance or identity protection product, and provides a monitoring
            service only; and
          </li>
          <li>
            confirm that you have read and agree to these Terms and the Additional Terms (see point 1. above). Maxwell
            Forest may cancel and refund your contribution at any time and for any reason, with no obligation to fulfil
            the order.
          </li>
        </ol>

        <h6 className="legal-returns-app__para-subtitle">2.2 General Orders</h6>
        <p>Maxwell Forest may make Safedome available for purchase through the Site.</p>

        <p>By undertaking a General Order you:</p>

        <ol>
          <li>agree that the&nbsp;sale of any Safedome cards is subject to these Terms;</li>
          <li>
            agree to pay any amounts and fees charged for your purchase of Safedome&nbsp;as set out on the Site
            including all Taxes (as defined below). Prices do not include shipping and handling unless
            specified.&nbsp;Before your payment is processed you will be given the opportunity to review and accept the
            amount you will be charged for your order including any Taxes and shipping and handling fees. All payments
            are in U.S. Dollars and are non-refundable, except as otherwise stated in these Terms. Maxwell Forest may
            change the required payment for any feature of the Service, including the price for each Safedome Card, on a
            going-forward basis at any time. You authorize Maxwell Forest to charge the full amount of your order
            reviewed and accepted by you per above to your specified payment method at the time of purchase;
          </li>
          <li>confirm that you are over the age of 18;</li>
          <li>
            acknowledge that Safedome is not an insurance or identity protection product, and provides a monitoring
            service only; and
          </li>
          <li>confirm that you have read and agree to these Terms and the Additional Terms (see point 1. above).</li>
        </ol>

        <p>
          In these Terms, “Taxes” means all sales and use taxes, imposts, charges and withholdings imposed on your
          purchase of Safedome contemplated under these Terms of Service, including but not limited to: value added tax,
          goods and services tax and sales, use, service, or consumption taxes.
        </p>

        <h6 className="legal-returns-app__para-subtitle">2.3 Refunds and Returns</h6>
        <p>
          Except as explicitly set forth in the Terms, Maxwell Forest will not issue a refund or exchange for any
          feature of the Service. As non-limiting examples, Maxwell Forest will not refund or exchange if you:
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>change your mind;</li>
          <li>mistakenly purchased the Service; or</li>
          <li>cannot provide proof of purchase and reasonable basis for your request.</li>
        </ol>

        <p>
          Maxwell Forest offers the limited warranty on cards that is set forth in in the Safedome Card Limited
          Warranty. Please refer to the Safedome Card Limited Warranty for terms and conditions for this warranty.
          Pre-orders are final and non-refundable upon receipt of payment except if the pre-ordered Services are deemed
          by Maxwell Forest as undeliverable.
        </p>

        <h6 className="legal-returns-app__para-subtitle">2.4 Shipping</h6>
        <p>
          All orders placed with Maxwell Forest are made pursuant to a shipment contract. By placing a pre-order on or
          after July 10, 2015, you acknowledge that the product is available within the United States and Australia only
          and will not commence shipment until the dates disclosed on the Site and at checkout.
        </p>

        <p>
          We will utilize commercially reasonable efforts to deliver the Service on the official dates disclosed on the
          Site. We reserve the right to extend or otherwise amend these dates at our discretion.
        </p>

        <p>
          Maxwell Forest cannot guarantee delivery until such a time as the products and services are available and
          ready for distribution. We shall not be liable for unforeseen or extenuating circumstances which delay or
          prevent the delivery of any feature of the Service.
        </p>

        <h6>3. RIGHTS AND TERMS FOR APPS</h6>
        <h6 className="legal-returns-app__para-subtitle">3.1 Rights in App</h6>
        <p>
          Subject to your compliance with these Terms, Maxwell Forest grants you a limited non-exclusive, personal,
          revocable, non-transferable, non-sublicenseable and non-assignable license to download and install a copy of
          the App on a mobile device or computer that you own or control and to run such copy of the App solely for your
          own personal non-commercial purposes. You may not copy the App, except for making a reasonable number of
          copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not: (i) copy,
          modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent
          the App to any third party; (iii) reverse engineer, decompile or disassemble the App; or (iv) make the
          functionality of the App available to multiple users through any means. Maxwell Forest reserves all rights in
          and to the App not expressly granted to you under these Terms.
        </p>

        <p>
          The App will be accessible for download from the App Store and Google Play (the “App Provider”). You agree to
          use the App only as permitted by the “Usage Rules” set forth in the App Store and Google Play Terms of
          Service.
        </p>

        <p>You acknowledge and agree that:</p>

        <ul>
          <li>
            These Terms are concluded between you and Maxwell Forest, and not with App Provider, and that, as between
            Maxwell Forest and the App Provider, Maxwell Forest, is solely responsible for the App.
          </li>
          <li>
            App Provider has no obligation to furnish any maintenance and support services with respect to the App.
          </li>
          <li>
            In the event of any failure of the App to conform to any applicable warranty, you may notify App Provider
            and App Provider will refund the monies paid by you directly to the App Provider for the App (if applicable)
            to you and, to the maximum extent permitted by applicable law, App Provider will have no other warranty
            obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or
            expenses attributable to any failure of an App to conform to any warranty will be the sole responsibility of
            Maxwell Forest.
          </li>
          <li>
            App Provider is not responsible for addressing any claims you have or any claims of any third party relating
            to the App or your possession and use of the App, including, but not limited to: (i) product liability
            claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and
            (iii) claims arising under consumer protection or similar legislation.
          </li>
          <li>
            In the event of any third-party claim that the App or your possession and use of the App infringes that
            third party’s intellectual property rights, Maxwell Forest will be solely responsible for the investigation,
            defense, settlement and discharge of any such intellectual property infringement claim to the extent
            required by these Terms.
          </li>
          <li>
            App Provider and its subsidiaries are third-party beneficiaries of these Terms as related to your license of
            the App, and that, upon your acceptance of the terms and conditions of these Terms, App Provider will have
            the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license
            of the App against you as a third party beneficiary thereof.
          </li>
          <li>You must also comply with all applicable third-party terms of service when using the App.</li>
          <li>
            You agree to comply with all U.S. or Australian (as applicable) and foreign export laws and regulations to
            ensure that neither the App nor any technical data related thereto nor any direct product thereof is
            exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such
            laws and regulations. By using the App you represent and warrant that: (i) you are not located in a country
            that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a
            “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or
            restricted parties.
          </li>
        </ul>

        <h6>4. INTEGRATED SERVICES</h6>
        <h6 className="legal-returns-app__para-subtitle">4.1 Identity Monitoring Services.</h6>
        <p>
          By downloading the App and/or by pre-ordering the Safedome card pursuant to the process set forth in Section
          2, you will receive access to the Identity Monitoring Services as described below.
        </p>
        <p>
          Maxwell Forest provides identity monitoring service, via PwnedList.com. PwnedList.com has the most
          comprehensive database of breached credentials such as email addresses, usernames and social media accounts.
          By continuously uncovering compromised credentials from sources such as hacker dump sites, the black market,
          hacktivist forums, file sharing portals, data leaks, key logger dumps, and malware logs, PwnedList finds data
          leaks before they become public knowledge. In the Identity section of the Safedome app you will be able to
          enter your email addresses.
        </p>
        <p>
          Safedome scans the PwnedList database to check if your email addresses are matched in the database. If there
          is a match, you’ll be notified and encouraged to update your email passwords and other identity details.
        </p>

        <h6 className="legal-returns-app__para-subtitle">4.2 Support Services.</h6>
        <p>
          By purchasing the Safedome card pursuant to the process set forth in Section 2, you receive access to the
          Support Services described here for the period commencing upon shipment of the Safedome card and expiring 12
          months thereafter (the “Service Term”). The Identity Monitoring Services and Support Services are referred to
          herein as the “Integrated Services.”
        </p>

        <h6 className="legal-returns-app__para-subtitle">4.3 Linking Accounts.</h6>
        <p>
          Some features of the Service may allow you to link your email accounts with the Integrated Services. By
          linking your e-mail to an Integrated Service, you confirm that the e-mail account(s) are your own and that you
          have permission to access, view and use the account(s). Use of Integrated Services through Safedome in this
          manner may be subject to additional terms established by the applicable third parties providing the Integrated
          Services, and it is your responsibility to comply with such terms. More information about Integrated Services
          and your data can be found in the Safedome Privacy Policy.
        </p>

        <h6>5. THIRD PARTY CONTENT</h6>
        <h6 className="legal-returns-app__para-subtitle">5.1 General</h6>
        <p>
          The Service may contain or display various materials and content from third parties (“Third Party Materials”).
          This does not in any way imply, suggest or constitute endorsement, sponsorship, or approval by Maxwell Forest
          of any third party or affiliation between any such third party and Maxwell Forest. In using and accessing the
          Service you agree that Maxwell Forest is in no way responsible for the accuracy, timeliness, or completeness
          of Third Party Materials. Your interaction with any third party accessed through the Service, online or
          offline, is at your own risk and Maxwell Forest will have no liability with respect to the acts, omissions,
          errors, representations, warranties, breaches or negligence of any such third party or for any personal
          injuries, death, property damage, or other damages or expenses resulting from your interactions with the third
          party.
        </p>

        <h6 className="legal-returns-app__para-subtitle">5.2 References and Links to External Sites</h6>
        <p>
          The Service may contain, display or make references to links to Third Party Materials and websites not
          controlled by Maxwell Forest. Maxwell Forest provides such information and links as a convenience to you and
          such links and references should not be considered endorsements or recommendations of such sites or any
          content, productions or information offered on such sites. You acknowledge and agree that Maxwell Forest is
          not responsible for any aspect of the information or content contained in any Third Party Materials or sites
          accessible via or linked from the Service.
        </p>

        <h6>6. USE RESTRICTIONS</h6>
        <p>Maxwell Forest does not permit use of, and you will not use, the Service for or to:</p>
        <ul>
          <li>
            Access, tamper with, or use non-public areas of the Services, Maxwell Forest’s computer systems, or the
            technical delivery systems of Maxwell Forest’s providers;
          </li>
          <li>
            Attempt to probe, scan or test the vulnerability of any Maxwell Forest system or network or breach any
            security or authentication measures;
          </li>
          <li>
            Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure
            implemented by Maxwell Forest or any of Maxwell Forest’s providers or any other third party (including
            another user) to protect the Services;
          </li>
          <li>
            Attempt to access or search the Services through the use of any engine, software, tool, agent, device or
            mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software
            and/or search agents provided by Maxwell Forest or other generally available third-party web browsers;
          </li>
          <li>
            Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party
            or in any manner not permitted by these Terms;
          </li>
          <li>
            Attempt to decipher, decompile, disassemble or reverse engineer any of the technology or software used to
            provide the Services;
          </li>
          <li>
            Interfere with, or attempt to interfere with, the access of any user, host or network, including, without
            limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
          </li>
          <li>any illegal or abusive purposes, or to violate any applicable law or regulation; or</li>
          <li>Encourage or enable any other individual or organisation to do any of the foregoing.</li>
        </ul>

        <h6>7. LOST, DAMAGED OR STOLEN CARDS</h6>
        <p>
          In the event that your Safedome Card is lost, stolen, or damaged you may purchase a replacement card directly
          from Maxwell Forest via the Site. Please note that your purchase of a new Safedome card may require you to
          update some or all of the information that you previously provided to Maxwell Forest.
        </p>

        <h6>8. INTELLECTUAL PROPERTY AND RESERVATION OF RIGHTS</h6>
        <p>
          The Service and Site are owned and operated by Maxwell Forest. The software, content, visual interface,
          interactive features, information, trademarks, logos, graphics, design, compilation, code, products, services,
          and all other elements of the Service (“Materials”) are protected by copyright, trade dress, patent, trade
          secret, and trademark laws of the United States and other jurisdictions, international conventions, and all
          other relevant intellectual property and proprietary rights, and applicable laws.
        </p>
        <p>
          All Materials, including intellectual property rights, are the property of Maxwell Forest and its subsidiaries
          or affiliated companies, including third-party licensors. You may not sell, license, distribute, copy, modify,
          publicly display, transmit, publish, edit, adapt or create derivative works from any Materials without the
          express written consent of Maxwell Forest.
        </p>
        <p>
          Maxwell Forest reserves all rights not expressly granted in these Terms. You shall not acquire any right,
          title or interest to any Materials whether by implication, estoppel, or otherwise, except for the limited
          rights set forth in these terms.
        </p>

        <h6>9. INDEMNITY</h6>
        <p>
          You agree that you will be responsible for your use of the Service, and you agree to defend, indemnify, and
          hold harmless Maxwell Forest and its officers, directors, employees, consultants, affiliates, subsidiaries and
          agents (collectively “Maxwell Forest entities”) from and against any and all claims, liabilities, damages,
          losses, and expenses, including reasonable attorney’s fees and costs arising out of or in any way connected
          with:
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>Your access to, use of, or alleged use of the Service;</li>
          <li>
            Your violation of these Terms or any representation, warranty or agreements referenced herein, or any
            applicable law or regulation;
          </li>
          <li>Any disputes or issues between you and any third party.</li>
        </ol>

        <p>
          Maxwell Forest reserves the right, at our own expense, to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by you (and without limiting your indemnification obligations with
          respect to such matter) and in such case, you agree to cooperate with Maxwell Forest’s claim.
        </p>

        <h6>10. WARRANTY DISCLAIMER</h6>
        <p>
          THE SERVICES AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS
          AVAILABLE” BASIS, WITHOUT THE WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESSED OR IMPLIED, EXCEPT AS
          EXPRESSLY STATED IN THE SAFEDOME CARD LIMITED WARRANTY. EXCEPT AS SET FORTH IN THE SAFEDOME CARD LIMITED
          WARRANTY, MAXWELL FOREST SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESSED OR IMPLIED, RELATING TO THE SERVICE, INCLUDING BUT NOT LIMITED TO:
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
            NON-INFRINGEMENT; AND
          </li>
          <li>ANY WARRANTIES ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE.</li>
        </ol>

        <p>
          MAXWELL FOREST DOES NOT WARRANT THAT THE SERVICE OR ANY PART THEREOF, OR ANY MATERIALS OR CONTENT OFFERED
          THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS,
          AND DOES NOT WARRANT THAT ANY OF THE FOREGOING WILL BE CORRECTED.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR ANY MATERIALS OR
          CONTENT AVAILABLE ON OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING MAXWELL FOREST OR THE SERVICE
          THAT IS NOT EXPRESSLY STATED IN THE SAFEDOME CARD LIMITED WARRANTY.
        </p>
        <p>
          YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE AND ANY MATERIALS OR CONTENT THROUGH THE SERVICE AND ANY
          ASSOCIATED SITES OR SERVICES IS AT YOUR OWN DISCRETION AND RISK.
        </p>
        <p>
          ANY CONDITION OR WARRANTY WHICH WOULD BE IMPLIED BY LAW (INCLUDING BY THE SALE OF GOODS ACT 1923 (NSW) AND ANY
          SIMILAR AUSTRALIAN STATE BASED LEGISLATION) INTO THESE TERMS (INCLUDING THE ADDITIONAL TERMS) IS EXCLUDED.
        </p>
        <p>
          SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY BETWEEN
          JURISDICTIONS.
        </p>

        <h6>11. LIMITATION OF LIABILITY</h6>
        <h6 className="legal-returns-app__para-subtitle">11.1 General</h6>
        <p>
          UNDER NO CIRCUMSTANCES WILL MAXWELL FOREST BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE LOSSES OR DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS, GOODWILL, USE,
          DATA OR OTHER INTANGIBLE LOSSES) ARISING OUT OF OR RELATING TO MAXWELL FOREST'S BREACH OF THE TERMS (INCLUDING
          THE ADDITIONAL TERMS), YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE SERVICE, OR ANY MATERIALS
          OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY
          OTHER LEGAL THEORY, WHETHER OR NOT MAXWELL FOREST WAS AWARE OR HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
          LOSS OR DAMAGE.{' '}
        </p>
        <p>
          YOU AGREE THAT THE AGGREGATE LIABILITY OF MAXWELL FOREST TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF RELATING
          TO THE USE OF OR ANY INABILITY TO USE THE SERVICE (INCLUDING ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
          SERVICE) OR OTHERWISE UNDER THESE TERMS (INCLUDING THE ADDITIONAL TERMS), WHETHER IN CONTRACT, TORT (INCLUDING
          NEGLIGENCE) OR OTHERWISE, IS LIMITED TO THE GREATER OF:
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>
            THE AMOUNTS YOU HAVE PAID TO MAXWELL FOREST FOR ACCESS TO, AND USE OF, THE SERVICE IN THE 12 MONTHS PRIOR TO
            THE CLAIM; AND
          </li>
          <li>$ 5</li>
        </ol>

        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
          DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </p>

        <p>
          EACH PROVISION OF THESE TERMS (INCLUDING THE ADDITIONAL TERMS) THAT PROVIDES FOR A LIMITATION OF LIABILITY,
          DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS UNDER THESE TERMS (INCLUDING THE
          ADDITIONAL TERMS) BETWEEN PARTIES. THIS IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE
          PARTIES. YOU ACKNOWLEDGE AND AGREE THAT MAXWELL FOREST HAS OFFERED ITS SERVICES AND PRODUCTS, SET ITS PRICES,
          AND ENTERED INTO THIS AGREEMENT IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET
          FORTH HEREIN, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A
          REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND MAXWELL FOREST.
        </p>

        <p>
          EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS (INCLUDING THE
          ADDITIONAL TERMS). THE LIMITATION IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL
          PURPOSE.
        </p>

        <p>
          THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED AND WILL
          NOT APPLY TO THESE TERMS (INCLUDING THE ADDITIONAL TERMS).
        </p>

        <h6 className="legal-returns-app__para-subtitle">11.2 Australian Resident Consumers</h6>
        <p>
          Notwithstanding any other provision of these Terms, if you are an Australian resident Consumer (as defined in
          Schedule 2 of the Competition and Consumer Act 2010 (Cth) and the corresponding provisions of the Australian
          Consumer Law (New South Wales) (“Australian Consumer Law”)):
        </p>

        <p className="legal-returns-app__para--indent--1">
          (1) all exclusions or limitations of liability under these Terms (including the Additional Terms) do not apply
          to any liability of Maxwell Forest to fail to comply with an applicable consumer guarantee under the
          Australian Consumer Law;
        </p>
        <p className="legal-returns-app__para--indent--1">
          (2) subject to paragraph (3) below, the liability of Maxwell Forest for any liability, loss, cost or damage,
          however caused (including by the negligence of Maxwell Forest), suffered or incurred by you because of a
          failure to comply with a consumer guarantee is limited for Maxwell Forest (at its election):
        </p>

        <p className="legal-returns-app__para--indent--2">
          (A) for services supplied under these Terms, to the cost of:
        </p>
        <ul>
          <li className="legal-returns-app__para--indent--2">resupplying the services; or</li>
          <li className="legal-returns-app__para--indent--2">
            paying the cost of having the services supplied again; or
          </li>
        </ul>

        <p className="legal-returns-app__para--indent--2">(B) for goods supplied under these Terms, to the cost of:</p>
        <ul>
          <li className="legal-returns-app__para--indent--2">
            resupplying the services; orrepairing or replacing the goods;
          </li>
          <li className="legal-returns-app__para--indent--2">supplying equivalent goods; or</li>
          <li className="legal-returns-app__para--indent--2">
            paying the cost to have the goods replaced or repaired.
          </li>
        </ul>

        <p className="legal-returns-app__para--indent--1">
          (3) Section 11.2 (2) does not apply if it is not fair or reasonable for Maxwell Forest to rely on it for the
          purposes of section 64A of the Australian Consumer Law.
        </p>

        <h6>12. DISPUTE RESOLUTION</h6>
        <h6 className="legal-returns-app__para-subtitle">12.1 Dispute Resolution</h6>
        <p className="legal-returns-app__para--underscore">Governing Law</p>
        <p>
          These Terms and any action related thereto will be governed by the laws of the State of California without
          regard to its conflict of laws provisions.
        </p>

        <p className="legal-returns-app__para--underscore">Agreement to Arbitrate</p>
        <p>
          You and Maxwell Forest agree that any dispute, claim or controversy arising out of or relating to these Terms
          or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services
          (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right:
          (i) to bring an individual action in small claims court and (ii) to seek injunctive or other equitable relief
          in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or
          violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights
          (the action described in the foregoing clause (ii), an “IP Protection Action”). The exclusive jurisdiction and
          venue of any IP Protection Action will be the state and federal courts located in the Northern District of
          California and each of the parties hereto waives any objection to jurisdiction and venue in such courts. You
          acknowledge and agree that you and Maxwell Forest are each waiving the right to a trial by jury or to
          participate as a plaintiff or class member in any purported class action or representative proceeding.
          Further, unless both you and Maxwell Forest otherwise agree in writing, the arbitrator may not consolidate
          more than one person's claims, and may not otherwise preside over any form of any class or representative
          proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution”
          section will be deemed void. Except as provided in the preceding sentence, this “Dispute Resolution” section
          will survive any termination of these Terms.
        </p>

        <p className="legal-returns-app__para--underscore">Arbitration Rules</p>
        <p>
          The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the
          Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”)
          then in effect, except as modified by this “Dispute Resolution” section. (The AAA Rules are available at
          www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the
          interpretation and enforcement of this Section.
        </p>

        <p className="legal-returns-app__para--underscore">Arbitration Process</p>
        <p>
          A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration
          as specified in the AAA Rules. (The AAA provides a form Demand for Arbitration at
          http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015820 and a separate form for California residents at
          http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822.) The arbitrator will be either a retired judge or an
          attorney licensed to practice law and will be selected by the parties from the AAA’s roster of arbitrators. If
          the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for
          Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules.
        </p>

        <p className="legal-returns-app__para--underscore">Arbitration Location and Procedure</p>
        <p>
          Unless you and Maxwell Forest otherwise agree, the arbitration will be conducted in the county where you
          reside. If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of
          the documents that you and Maxwell Forest submit to the arbitrator, unless you request a hearing or the
          arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will
          be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a
          reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.
        </p>

        <p className="legal-returns-app__para--underscore">Arbitrator’s Decision</p>
        <p>
          The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s
          decision will include the essential findings and conclusions upon which the arbitrator based the award.
          Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s
          award of damages must be consistent with the terms of the “Limitation of Liability” section above as to the
          types and amounts of damages for which a party may be held liable. The arbitrator may award declaratory or
          injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted
          by the claimant’s individual claim. If you prevail in arbitration you will be entitled to an award of
          attorneys’ fees and expenses, to the extent provided under applicable law. Maxwell Forest will not seek, and
          hereby waives all rights it may have under applicable law to recover, attorneys’ fees and expenses if it
          prevails in arbitration.
        </p>

        <p className="legal-returns-app__para--underscore">Fees</p>
        <p>
          Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in
          the AAA Rules. However, if your claim for damages does not exceed $75,000, Maxwell Forest will pay all such
          fees unless the arbitrator finds that either the substance of your claim or the relief sought in your Demand
          for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth
          in Federal Rule of Civil Procedure 11(b)).
        </p>

        <p className="legal-returns-app__para--underscore">Changes</p>
        <p>
          Notwithstanding the provisions of the “Right to Modify” section below if Maxwell Forest changes this “Dispute
          Resolution” section after the date you first accepted these Terms (or accepted any subsequent changes to these
          Terms), you may reject any such change by sending us written notice (including by email to
          customercare@safedome.com) within 30 days of the date such change became effective, as indicated in the “Last
          Updated” date below or in the date of Maxwell Forest’s email to you notifying you of such change. By rejecting
          any change, you are agreeing that you will arbitrate any Dispute between you and Maxwell Forest in accordance
          with the provisions of this “Dispute Resolution” section as of the date you first accepted these Terms (or
          accepted any subsequent changes to these Terms).
        </p>

        <h6>13. TERMINATION & RIGHT TO MODIFY</h6>
        <p>
          We may terminate your access to and use of the Services, at our sole discretion, at any time and without
          notice to you. Upon any termination, discontinuation or cancellation of Services or your Account, all
          provisions of these Terms which by their nature should survive will survive, including, without limitation,
          ownership provisions, warranty disclaimers, use restrictions, limitations of liability, indemnities, and
          dispute resolution provisions. We reserve the right to modify or discontinue the Service at any time, with or
          without notice to you. If we terminate you from the Service prior to expiration of the Service Term, we will
          refund a pro-rated amount of any fees you paid to order your Safedome card based on the fraction of the
          Service Term that remains upon the effective date of such termination. We will have no other liability
          whatsoever on account of any change to the Service or any suspension or termination of your access to or use
          of the Service. You may terminate your account at any time by contacting customercare@safedome.com.
        </p>

        <h6>14. GENERAL PROVISIONS</h6>
        <h6 className="legal-returns-app__para-subtitle">14.1 Entire Agreement</h6>
        <p>
          These Terms (including the Additional Terms) constitute the entire agreement between you and Maxwell Forest
          regarding your use of the Service, and supersedes all prior and contemporaneous agreements, proposals,
          representations, negotiations or other communications - written or oral - between you and Maxwell Forest, with
          respect to the subject matter. No modification, amendment, or waiver of any provision of this Master Terms of
          Service will be effective unless in writing and signed by the party against whom the modification, amendment
          or waiver is to be asserted. The parties agree that any terms or conditions stated in your purchase order or
          in any other of your order documentation (excluding any order forms) is void.
        </p>

        <h6 className="legal-returns-app__para-subtitle">14.2 Assignment</h6>
        <p>
          You may not assign or transfer these Terms, by operation of law or otherwise, without Maxwell Forest’s prior
          written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null.
          Maxwell Forest may freely assign or transfer these Terms without restriction. Subject to the foregoing, these
          Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
        </p>

        <h6 className="legal-returns-app__para-subtitle">14.3 Waiver</h6>
        <p>
          No failure or delay by either party in exercising or enforcing any right under these Terms will constitute a
          waiver of that right.
        </p>

        <h6 className="legal-returns-app__para-subtitle">14.4 Severability</h6>
        <p>
          If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or
          unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of
          these Terms will remain in full force and effect.
        </p>

        <h6 className="legal-returns-app__para-subtitle">14.5 Export Compliance</h6>
        <p>
          The Service, Site and any associated content and technology made available by Maxwell Forest may be subject to
          export laws and regulations of the United States and other jurisdictions. You may not export or re-export any
          element of the Service without
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>Receiving prior written consent from Maxwell Forest;</li>
          <li>Complying with any applicable export control laws; and</li>
          <li>Obtaining all appropriate permissions, permits and licenses.</li>
        </ol>

        <p>
          Under no circumstances may you transfer or authorize the transfer of any element of the Service to a
          prohibited territory, country or organization, or otherwise in violation of any applicable restrictions or
          regulations.{' '}
        </p>

        <h6 className="legal-returns-app__para-subtitle">14.6 Force Majeure</h6>
        <p>
          By using the Service you acknowledge and understand that neither Maxwell Forest or its third party service
          providers shall be deemed in default hereunder, nor shall it hold the other party responsible for, any
          cessation, interruption or delay in the performance of its obligations hereunder due to causes beyond its
          reasonable control including, but not limited to: earthquake, flood, fire, storm or other natural disaster,
          act of God, labor controversy or threat thereof, civil disturbance or commotion, disruption of the public
          markets, war or armed conflict or the inability to obtain sufficient material, supplies, labor,
          transportation, power or other essential commodity or service required in the conduct of its business,
          including internet access, or any change in or the adoption of any law, ordinance, rule, regulation, order,
          judgment or decree.
        </p>

        <h6>15. NOTICE.</h6>
        <p>
          Any notices or other communications provided by Maxwell Forest under these Terms, including those regarding
          modifications to these Terms, will be given: (i) via email; or (ii) by posting to the Services. For notices
          made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted. If you have
          any questions about these Terms, please contact Maxwell Forest at
        </p>

        <p>
          28 2nd Street, Floor 3, <br />
          San Francisco, <br />
          CA 94105, USA
          <br />
          <a href="mailto:customercare@safedome.com ">customercare@safedome.com </a> <br />
          +1 (888) 417 5550
        </p>

        <p>&nbsp;</p>
        <p>Last updated: September 4, 2015</p>
      </div>
    </div>
  </Layout>
);

export default Terms;
